import axios from 'axios';
import Cookies from 'universal-cookie';
import {
  getAccessToken,
  getRefreshToken,
  updateAccessToken,
} from '../src/pages/login/cookies';





let baseURL = 'http://localhost:4000'
// let wsUrl = 'ws://localhost:4000'

if (process.env.NODE_ENV === 'production') {

  
  baseURL = window.location.host === '3dcafe.in' ? 'https://server.3dcafe.in': 'https://3dcafeserver.food.vttech.dev' 
  // wsUrl = 'wss://server.cespl.xyz'
}

// const cookies = new Cookies();

const instance = axios.create({
  baseURL,
  timeout: 100000,
  headers: {
    'Content-type': 'application/json',
  }
});

// instance.interceptors.request.use(
//   (config) => {
//     let token = getAccessToken();
//     if (token) {
//       config.headers['Authorization'] = 'Bearer ' + token;

//     }
//     config.headers['Content-Type'] = 'application/json';
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   },
// );


// let refreshTokenPromise // this holds any in-progress token refresh requests

// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
   
//   const originalRequest = error.config;
//   //Axios interceptor restrict Infinite loop 
//       if ((error.response?.status === 401) && !originalRequest._retry) {
//         originalRequest._retry = true;
//         return Promise.reject(error);
//       }
//       console.log('errorrespnss', error)
//     if((error?.response?.status === 403) &&
//       (error.response.data.message === 'Subscription Expired')){
//         // alert('Subscription Expired')
//         console.log('Subscription Expired')
//         cookies.remove('login');
//         localStorage.clear();
//         sessionStorage.clear();
//         // window.location.replace(`${window.location.origin}/signin`);
//     }
//     else if ((error?.response?.status === 403) && 
//       (error.response.data.message === 'token_expired') &&
//       (originalRequest.url !== '/renewAccessToken') && 
//       (originalRequest.url !== '/login')) {
//     if (!refreshTokenPromise) { // check for an existing in-progress request
//       // // if nothing is in-progress, start a new refresh token request
//         let userName = cookies.get('login')[0]?.cUserName;
//         // let userId
//         console.log('uName',userName)
//       refreshTokenPromise = instance.post('/renewAccessToken', {refreshToken: getRefreshToken()}).then(token => {
//         updateAccessToken(token.data.accessToken);
//         refreshTokenPromise = null // clear state
//         return token.data.accessToken // resolve with the new token
//       })
//     }
//     return refreshTokenPromise
//     .then(token => {
//       instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
//       return instance(originalRequest);
//     })
//     .catch((err) => {
//       // alert('Token Expired')
//       cookies.remove('login');
//         localStorage.clear();
//         sessionStorage.clear();
//       // window.location.replace(`${window.location.origin}/login`);
//       return Promise.reject(error)

//     });
//   }
//   return Promise.reject(error)
// })

export const base_url = baseURL;
// export const ws_url = wsUrl;
export default instance;
